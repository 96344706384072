import React from 'react';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';

const HomeTemplate = props => {
  const {
    pageContext: { lang, slug }
  } = props;

  const i18n = new I18n({ lang });
  let s3AssetsPath = process.env.GATSBY_S3_ASSETS_PATH;
  
  return (
    <Layout slug={slug}>
      <tm-page className="theme--trailhead">
          <tm-hero-a
            title={i18n.text('hero_title')}
            subtitle={i18n.text('hero_subtitle')}
            cta-label={i18n.text('hero_ctaText')}
            cta-href="https://trailhead.com/slack-app-install"
            img-src={`${s3AssetsPath}/slack-hero.png`}
            img-alt={i18n.text('hero_image_altText')}
            img-align="bottom"
          ></tm-hero-a>
        
        <tm-section-a
          title={i18n.text('section_A_title')}
          background="light">
          <tm-feature-grid-a>
            <tm-grid-item
              img-src={`${s3AssetsPath}/share-slack.png`}
              img-alt={i18n.text("feature_1_image_altText")}
              title={i18n.text("feature_1_title")}
              description={i18n.text("feature_1_description")}
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/nudges-slack.png`}
              img-alt={i18n.text("feature_2_image_altText")}
              title={i18n.text("feature_2_title")}
              description={i18n.text("feature_2_description")}
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/home-slack.png`}
              img-alt={i18n.text("feature_3_image_altText")}
              title={i18n.text("feature_3_title")}
              description={i18n.text("feature_3_description")}
            ></tm-grid-item>
          </tm-feature-grid-a>
        </tm-section-a>


          <tm-end-cap-a
            background="dark"
            img-src={`${s3AssetsPath}/slack-footer.png`}
            img-alt=""
            title={i18n.text('endCap_title')}
            cta-label={i18n.text('endCap_ctaLabel')}
            cta-href="https://trailhead.com/slack-app-install"
          ></tm-end-cap-a>

      </tm-page>
    </Layout>
  );
};

export default HomeTemplate;
