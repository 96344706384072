/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import labels from "../../data/labels.json"

class i18n extends React.Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.defaultLabels = labels.en;
    this.currentLabels = this.defaultLabels;
    this.allLabels = labels;
    this.allLangs = [];
    

    this.requireLabels();
  }

  requireLabels = () => {
   
    this.currentLabels = Object.hasOwnProperty.call(labels, this.lang) ? labels[this.lang] : labels.en;
    
  };

  text = (label, prms) => {
    let text = '';
    if (prms === 0) {
      prms = '0';
    }

    if (Object.prototype.hasOwnProperty.call(this.currentLabels, label)) {
      text = this.currentLabels[label];

    } else if (Object.prototype.hasOwnProperty.call(this.defaultLabels, label)) {
      text = this.defaultLabels[label];
    }

    let params = [];

    if (prms) {
      if (Array.isArray(prms)) {
        params = prms;
      } else {
        params.push(prms);
      }
    }

    params.forEach((param, index) => {
      text = text.replace(`{${index}}`, String(param));
    });

    text = text.replace(/\{\d+\}/, '');
    text = this.ReplaceCustomTags(text);

    return text;
  };

  ReplaceCustomTags = (text) => {
    let result = text;
    result = result.replace(/<f=tb>/g, '<span class="Ff(trailheadBold)">');
    result = result.replace(/<f=nft>/g, '<span class="Ff(neutraFaceTitling)">');
    result = result.replace(/<\/f>/g, '</span>');
    return result;
  };
}

i18n.propTypes = {
  lang: PropTypes.string.isRequired
};

export default i18n;
